import { observable, computed, action } from 'mobx'
import RootStore from 'src/common/RootStore'

import Organisation from 'src/common/models/Organisation'
import { PlayerName } from 'src/common/models/PlayerSyncState'
import Screen from 'src/common/models/Screen'

export default class PlayerUIStore {
    @observable private setOrg?: Organisation

    // RootStore
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @computed get selectedOrg(): Organisation | undefined {
        const orgStore = this.rootStore.orgStore
        // If not multiple screen manager, set to own org
        return !orgStore.hasMultipleOpsOrganisations ? orgStore.myOrg : this.setOrg
    }

    @computed get screens(): Screen[] {
        const screenStore = this.rootStore.screenStore
        // Adding unknown to the list until we fix client side flow to assign player in all instances
        const allowedPlayers = Object.values([PlayerName.outwatch, PlayerName.candelic, PlayerName.unknown]) as string[]
        return screenStore.items.filter(
            (screen: Screen) =>
                screen.organisationId === this.selectedOrg?.id &&
                screen.playerName !== undefined &&
                allowedPlayers.includes(screen.playerName)
        )
    }

    @action updateParams = (params: { org: string }) => {
        const { org } = params

        if (org) {
            this.setOrg = this.rootStore.orgStore.findItem(org)
        }
    }
}
